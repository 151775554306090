import { useReactiveVar } from "@apollo/client";
import { AppRoot } from "../layout/root";
import { restoreLogin } from "../utils/auth/login";
import { authVar, isReady } from "../state/auth";
import { useEffect } from "react";

export const Auth = () => {
  const auth = useReactiveVar(authVar);

  useEffect(() => {
    restoreLogin();
  }, []);

  if (!isReady(auth)) {
    return null;
  }

  return (
    <AppRoot />
  );
}
