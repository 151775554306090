import { Typography } from '@mui/material';
import './welcome.css';

export const Welcome = () => {
  return (
    <div className='tc-home-welcome'>
      <div className='tc-home-welcome-content'>
        <div className='tc-home-welcome-kicker'>
          <Typography variant='body1' gutterBottom>
            I AM YOUR ROBOT QUANT
          </Typography>
        </div>
        <Typography variant='h1' gutterBottom>
          AI-powered<br />
          stock market analysis
        </Typography>
        <div className='tc-home-welcome-description'>
          <Typography variant='body1' gutterBottom>
            Greetings, human. My purpose is to analyze stocks and nothing more.
            Choose a symbol below and learn.
          </Typography>
        </div>
      </div>
      <div className='tc-home-welcome-image'>
        <img src='/images/cube-clipped.png' />
      </div>
    </div>
  );
}
