import * as React from 'react';
import classnames from 'classnames';
import './explainer-item.css';

export interface ExplainerItemProps {
  name: string;
  value: string;
  accountingLine?: boolean;
}

export const ExplainerItem = ({ name, value, accountingLine }: ExplainerItemProps) => {
  const classNames = classnames({
    "tc-explainer-item": true,
    'tc-explainer-item-accounting': accountingLine
  });

  return (
    <div className={classNames}>
      <strong>{name}:</strong>
      <span>{value}</span>
    </div>
  );
}
