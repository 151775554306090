import { Logout } from "@mui/icons-material";
import { MenuItem, ListItemIcon } from "@mui/material";
import { logout } from "../../../utils/auth/login";

export const SignOut = () => {
  const signOut = () => {
    logout();
  };
  return (
    <MenuItem onClick={signOut}>
      <ListItemIcon>
        <Logout fontSize="small" />
      </ListItemIcon>
      Sign out
    </MenuItem>
  );
};
