import * as React from 'react';
import { SummaryReports } from '../../stock-types';
import { SummaryGlance } from './glance';
import * as _ from 'lodash';
import './glance-multi.css';

export interface GlanceMultiProps {
  reports: SummaryReports;
}

export const GlanceMulti = ({ reports }: GlanceMultiProps) => {
  const isMulti = reports.length > 1;
  return (
    <div className='tc-stock-summary-glance-multi-wrap'>
      {_.map(reports, report => <SummaryGlance key={report.summary.symbol} isMulti={isMulti} report={report.summary} />)}
    </div>
  );
}
