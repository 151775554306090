import { Footer } from "./footer/footer";
import { Header } from "./header/header";
import { RouteRoot } from "./routes";

export const AppRoot = () => {
  return (
    <div className='tc-root-app-wrap-outer'>
      <div className='tc-root-app-wrap-content'>
        <Header />
        <RouteRoot />
      </div>
      <div className='tc-root-app-wrap-footer'>
        <Footer />
      </div>
    </div>
  );
}
