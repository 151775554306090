import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import './desktop-menu.css';

export const DesktopMenu = () => {
  return (
    <div className='tc-header-desktop-menu'>
      <Button component={Link} to='/browse'>
        Browse
      </Button>
    </div>
  );
}

