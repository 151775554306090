import * as React from 'react';
import './explainer.css';

export interface ExplainerProps {
  children: React.ReactElement;
}

export const Explainer = (props: ExplainerProps) => {
  return (
    <div className="tc-explainer">
      {props.children}
    </div>
  );
}
