import { gql } from "@apollo/client";

export const summaryQuery = gql`
fragment MetricFragment on Metric {
  value
  connotation
  best
}

fragment ROIPeriod on SymbolROI {
  annualizedCapitalGainROI { ...MetricFragment }
  annualizedDividendYield { ...MetricFragment }
  annualizedTotalROI { ...MetricFragment }
  dividendFrequencyEstimate { ...MetricFragment }
  forwardDividendYield { ...MetricFragment }
  annualizedDRIPCapitalGainROI { ...MetricFragment }
  annualizedDRIPDividendROI { ...MetricFragment }
  annualizedTotalROIWithDRIP { ...MetricFragment }
  CAGR { ...MetricFragment }
  DRIPCAGR { ...MetricFragment }
}

query Symbol($symbols: [String]!, $token: String) {
  symbols(symbols: $symbols, token: $token) {
    symbols {
      symbol
      color
      watching
      summary {
        name
        symbol
        summary {
          description
          beta { ...MetricFragment }
        }
        sketches {
          name
          description
          url
        }
        roi {
          y1 {
            ...ROIPeriod
          }
          y5 {
            ...ROIPeriod
          }
        }
        dividend {
          paidEver
          paidRecently
          paymentsPerYear
          averagePaymentsPerYear
        }
        dividendsByMonth {
          date
          exDividendDate
          dividend
          percent
          annualizedYield
        }
        priceSnapshotMonths {
          date
          close
        }
        totalReturnSim {
          startingInvestment
          endingValue
          totalROI
          simDurationMonths
          annualizedReturn
          percentFromDividends
          valueHistory {
            DRIPValue
            stockValue
          }
        }
        riskRatios {
          date
          debtServiceCoverage { ...MetricFragment }
          interestCoverage { ...MetricFragment }
          current { ...MetricFragment }
          debtToEquity { ...MetricFragment }
          grossMargin { ...MetricFragment }
          operatingMargin { ...MetricFragment }
          netProfitMargin { ...MetricFragment }
          freeCashFlowYield { ...MetricFragment }
        }
        earnings {
          date
          currency
          netIncome { ...MetricFragment }
          ebit { ...MetricFragment }
          ebitda { ...MetricFragment }
          revenue { ...MetricFragment }
        }
        dispersal {
          date
          totalToDebtHolders { ...MetricFragment }
          dividendPayout { ...MetricFragment }
          buybacks { ...MetricFragment }
          totalToEquityHolders { ...MetricFragment }
          totalDistribution { ...MetricFragment }
          amountReinvested { ...MetricFragment }
          percentToDebtHolders { ...MetricFragment }
          percentToEquityHolders { ...MetricFragment }
          percentReinvested { ...MetricFragment }
        }
        dilution {
          history {
            date
            shares
            eps
            shareDilution
            epsDilution
          }
          dilution5yEps { ...MetricFragment }
          dilution5yShare { ...MetricFragment }
        }
        price {
          y5 {
            range {
              low
              high
              current
            }
          }
        }
        priceRatios {
          peRatio { ...MetricFragment }
          marketCap { ...MetricFragment }
        }
        lastUpdated
      }
    }
  }
}
`;
