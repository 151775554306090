import { Alert, Button, Card, CardContent, TextField, Typography } from "@mui/material";
import './signin.css';
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import * as _ from 'lodash';
import { hashPassword } from "../utils/auth/password";
import { useNavigate } from "react-router";
import { completeLogin } from "../utils/auth/login";

const SignInMutation = gql`
mutation signIn($input: SignInInput!) {
  signIn(input: $input) {
    success
    login {
      id
      user
      name
      admin
      premium
    }
  }
}
`;

const ERROR_LOGIN_FAILED = 'Could not login. Either the email or password entered is incorrect.';

export const Signin = () => {
  const [ emailAddress, setEmailAddress ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ errors, setErrors ] = useState<string[]>([]);
  const [ isLoading, setLoading ] = useState(false);
  const navigate = useNavigate();
  const [ signIn, response ] = useMutation(SignInMutation, {
    onCompleted: (data) => {
      const { login, success } = data.signIn;
      if (login) {
        completeLogin(login);
        navigate('/');
      }
      if (!success) {
        setErrors([ ERROR_LOGIN_FAILED ]);
        setLoading(false);
      }
    }
  });

  const onSubmit = async () => {
    setLoading(true);
    const hash = await hashPassword(password, emailAddress);
    signIn({
      variables: {
        input: {
          emailAddress,
          hash
        }
      }
    });
  }

  const combinedErrors = [...errors, ..._.get(response, ['data', 'errors'], [])];

  return (
    <>
      <div className="tc-signin-form-wrap">
        <div className="tc-container">
          <Card>
            <CardContent>
              <div className='tc-signin-header'>
                <Typography variant="h5" color="text.secondary">
                  Log in
                </Typography>
              </div>
              <div className='tc-signin-field'>
                <TextField
                  label='Email address'
                  value={emailAddress}
                  onChange={(ev) => setEmailAddress(ev.currentTarget.value)}
                  fullWidth
                />
              </div>
              <div className='tc-signin-field'>
                <TextField
                  label='Password'
                  type='password'
                  value={password}
                  onChange={(ev) => setPassword(ev.currentTarget.value)}
                  fullWidth
                />
              </div>
              {combinedErrors.map(err => (
                <div className='tc-signin-field'>
                  <Alert severity="error">{err}</Alert>
                </div>
              ))}
              <div className='tc-signin-field'>
                <Button
                  color='primary'
                  variant='contained'
                  disabled={isLoading}
                  onClick={onSubmit}
                >Log in</Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};
