import { LoginCompletion } from "../../api";
import { setAuthUser, setAuthGuest } from "../../state/auth";

const LOGIN_KEY = 'trainedcapital';

export const completeLogin = (login: LoginCompletion) => {
  localStorage.setItem(LOGIN_KEY, JSON.stringify(login));
  setAuthUser(login);
}

export const restoreLogin = () => {
  try {
    const loginStr = localStorage.getItem(LOGIN_KEY);
    if (loginStr) {
      const login = JSON.parse(loginStr);
      setAuthUser(login);
    } else {
      setAuthGuest();
    }
  } catch (err) {
    console.error(err);
  }
}

export const logout = () => {
  localStorage.removeItem(LOGIN_KEY);
  setAuthGuest();
}
