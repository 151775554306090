import * as React from 'react';
import { Card, CardContent, Typography } from "@mui/material";
import _ from "lodash";
import { ChartPreview } from "../../../../utils/chart-preview/chart-preview";
import { toPercent } from "../../../../utils/format/format";
import { SummaryReports } from '../../../stock-types';
import { flattenStructure } from '../../../../utils/chart-preview/utils';
import { Legend } from '../../../../utils/color/legend';
import { Connotation } from '../../../../utils/connote/connote';
import './dilution-card.css';

export interface DilutionCardProps {
  reports: SummaryReports;
}

export const DilutionCard = ({ reports }: DilutionCardProps) => {
  const isSingular = reports.length === 1;

  const reportsWithDilution = _.filter(reports, rep => rep.summary.dilution) as SummaryReports;
  const hasData = reportsWithDilution.length > 0

  const keyedReports = _.keyBy(reportsWithDilution, report => report.summary.symbol);
  const allDilution = _.mapValues(keyedReports, report => _.reverse(_.cloneDeep(report.summary.dilution.history)));
  const merged = flattenStructure(allDilution);

  const series = _.flatten(_.map(reportsWithDilution, report => ([
    {
      key: `${report.symbol}-epsDilution`,
      connotation: report.summary.dilution.dilution5yEps.connotation as string as Connotation,
      color: isSingular ? undefined : report.color,
      kicker: `${report.symbol}: EPS Value`
    },
    {
      key: `${report.symbol}-shareDilution`,
      connotation: Connotation.NEUTRAL,
      stroke: 1,
      color: isSingular ? undefined : report.color,
      kicker: `${report.symbol}: Share Value`
    },
  ])));

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" color="text.secondary">
          Dilution
        </Typography>
        {!hasData && (
          <div className='tc-summary-dilution-none'>
            <Typography variant='body2' color="text.secondary" gutterBottom>
              No dilution data is available
            </Typography>
          </div>
        )}
        {hasData && (
          <div className='tc-summary-chart-wrap'>
            <ChartPreview
              data={merged}
              series={series}
              showReference={false}
              format='percent'
            />  
          </div>
        )}
        {_.map(reportsWithDilution, report => {
          return (
            <div className='tc-summary-chart-price-item'>
              <div className='tc-summary-chart-price-stats'>
                {!isSingular && (
                  <Typography variant='body2' color="text.secondary" gutterBottom>
                    <strong>${report.symbol} <Legend color={report.color} /></strong>
                  </Typography>
                )}
                {_.get(report, ['summary', 'dilution', 'dilution5yEps', 'value'], false) && (
                  <div className='tc-summary-chart-price-stat'>
                    <div className='tc-summary-chart-price-stat-name'>
                      5Y Share Dilution
                    </div>
                    <div className='tc-summary-chart-price-stat-value'>
                      {toPercent(Math.abs(1 - report.summary.dilution.dilution5yShare.value))}
                    </div>
                  </div>
                )}
                {_.get(report, ['summary', 'dilution', 'dilution5yEps', 'value'], false) && (
                  <div className='tc-summary-chart-price-stat'>
                    <div className='tc-summary-chart-price-stat-name'>
                      5Y EPS {report.summary.dilution.dilution5yEps.value >= 1 ? 'Acretion' : 'Dilution'}
                    </div>
                    <div className='tc-summary-chart-price-stat-value'>
                      {toPercent(Math.abs(1 - report.summary.dilution.dilution5yEps.value))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </CardContent>
      {/*<CardActions>
        <Button size="small">More</Button>
      </CardActions>*/}
    </Card>
  );
}
