import { useMutation, useReactiveVar } from "@apollo/client";
import { CircularProgress, IconButton, Menu, MenuItem } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SummaryReports, RiskReports } from "../stock-types";
import { watchMutation, unwatchMutation } from "../watch/watch-mutations";
import { authVar } from "../../state/auth";

export interface StockMobileMenuProps {
  symbols: string[];
  reports: SummaryReports | RiskReports | null;
}

export const StockMobileMenu = ({ symbols, reports }: StockMobileMenuProps) => {
  const auth = useReactiveVar(authVar);
  const token = _.get(auth, ['login', 'id'], null);

  // Control menu expansion state
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  // Watching support
  const watchingAll = _.reduce(reports, (result, report) => result && _.get(report, ['watching'], false), true);
  const [ isWatching, setIsWatching ] = useState(watchingAll);
  const [ watch, watchResponse ] = useMutation(watchMutation, { onCompleted: () => setIsWatching(true) });
  const [ unwatch, unwatchResponse ] = useMutation(unwatchMutation, { onCompleted: () => setIsWatching(false) });

  const actions = [
    {
      name: 'Add to watchlist',
      isActive: !isWatching,
      execute: () => watch({ variables: { token, symbols } }),
      monitor: watchResponse
    },
    {
      name: 'Remove from watchlist',
      isActive: isWatching,
      execute: () => unwatch({ variables: { token, symbols } }),
      monitor: unwatchResponse
    }
  ];

  const onMenuItemClick = (action: any) => () => {
    action.execute();
  }

  return (
    <div className='tc-mobileonly'>
      <IconButton
        size="large"
        edge="start"
        color="primary"
        aria-label="menu"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {_.map(_.filter(actions, a => a.isActive), action => (
          <MenuItem disabled={action.monitor.loading} onClick={onMenuItemClick(action)}>
            {action.monitor.loading && <CircularProgress />}{' '}
            {action.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
