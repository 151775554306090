import { Card, CardContent, Typography } from "@mui/material";
import _ from "lodash";
import './top-risks.css';
import { DiscoveredRisk, RiskReport } from "../../../api";
import classNames from "classnames";
import { RiskReports } from "../../stock-types";

const keyNames: { [key: string]: string } = {
  decliningPopulation: 'Declining Population',
  decliningDollar: 'Declining Dollar',
  artificialIntelligence: 'Artificial Intelligence',
  climateRisk: 'Climate Risk',
  economicRecession: 'Economic Recession',
  cybersecurityRisk: 'Infrastructure Risk',
  pandemicRisk: 'Pandemic Risk',
  warRisk: 'War Risk',
};

const keyConnotationText: { [key: string]: string } = {
  'GOOD': 'Positive',
  'BAD': 'Negative'
};

export interface TopRiskItemProps {
  risk: DiscoveredRisk;
}

export const TopRiskItem = ({ risk }: TopRiskItemProps) => {
  const className = classNames({
    'tc-risk-top-item': true,
    'tc-risk-top-item-extreme': risk.volatility === 'EXTREME',
    'tc-risk-top-item-high': risk.volatility === 'HIGH',
    'tc-risk-top-item-medium': risk.volatility === 'MEDIUM',
    'tc-risk-top-item-low': risk.volatility === 'LOW',
  });
  return (
    <div className={className}>
      <div className='tc-risk-top-item-icon'>
      </div>
      <div className='tc-risk-top-item-name'>
        <strong>
          {risk.riskName}
          <span className='tc-risk-top-item-indication'>
            ({_.capitalize(risk.volatility)})
          </span>
        </strong> 
      </div>
      <div className='tc-risk-top-item-reason'>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {risk.riskDescription}
        </Typography>
      </div>
    </div>
  );
}

export interface TopRisksProps {
  reports: RiskReports;
}

export const TopRisks = ({ reports }: TopRisksProps) => {
  const className = classNames({
    'tc-risk-catastrophic-wrap': true,
    'tc-risk-catastrophic-wrap-multiple': reports.length > 1
  });
  return (
    <div className={className}>
      {_.map(reports, report => (
        <div className='tc-risk-catastrophic' key={report.symbol}>
          <Card>
            <CardContent>
            <Typography variant="h5" color="text.secondary" gutterBottom>
                {reports.length > 1 && (<>${report.symbol}:</>)}{' '}
                Top Risks
              </Typography>
              {_.map(report.risk.topRisks, (risk: DiscoveredRisk) => (
                <TopRiskItem
                  key={risk.riskName}
                  risk={risk}
                />
              ))}
            </CardContent>
          </Card>
      </div>
      ))}
    </div>
  );
}
