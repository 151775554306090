import { gql, useQuery } from "@apollo/client";
import { ListsCard } from "./lists/lists-card";
import { FullPageLoading } from "../utils/loading/loading";
import { Welcome } from "./welcome/welcome";
import { PageError } from "../utils/error/page-error";

const homeQuery = gql`
{
  home {
    picks {
      symbol
      name
      link
    }
  }
}
`;

export const HomePage = () => {
  const { data, loading, error } = useQuery(homeQuery);

  if (loading) {
    return <FullPageLoading />
  }

  if (error || !data) {
    return (
      <PageError />
    );
  }

  return (
    <>
      <Welcome />
      <div className="tc-container">
        <ListsCard lists={{ picks: data.home.picks }} />
      </div>
    </>
  );
}
