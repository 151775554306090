import * as React from 'react';
import { Button, Card, CardContent, Typography } from "@mui/material";
import * as _ from 'lodash';
import './expand-card.css';
import classNames from 'classnames';

export interface ExpandCardProps {
  children: React.ReactElement;
}

export const ExpandCard = ({ children }: ExpandCardProps) => {
  const [ expanded, setExpanded ] = React.useState(false);
  const className = classNames({
    'tc-expandcard-wrap': true,
    'tc-expandcard-wrap-expanded': expanded,
    'tc-expandcard-wrap-not-expanded': !expanded,
  });
  return (
    <Card>
      <CardContent className={className}>
        <div className='tc-expandcard-inner'>
          <div className='tc-expandcard-gradient'>

          </div>
          <div className='tc-expandcard-content'>
            {children}
          </div>
        </div>
        <div className='tc-expandcard-button'>
          <Button onClick={() => setExpanded(!expanded)}>More</Button>
        </div>
      </CardContent>
    </Card>
  );
}
