import { SymbolROI, SummaryReport } from "../../../api";
import * as _ from 'lodash';
import './glance.css';
import { toBigMoney, toMoneyLong, toPercent } from "../../../utils/format/format";
import { Metric } from "../../../utils/metric/metric";
import { Connotation, getConnotationFromPercent } from "../../../utils/connote/connote";
import { PeriodReturn } from "./period-return/period-return";
import { ExplainerItem } from "../../../utils/explainer/item/explainer-item";
import { Explainer } from "../../../utils/explainer/main/explainer";
import { ExplainerNote } from "../../../utils/explainer/note/explainer-note";
import { ExplainerSection } from "../../../utils/explainer/section/explainer-section";

export interface GlanceProps {
  report: SummaryReport;
  isMulti: boolean;
}

export const SummaryGlance = ({ report, isMulti }: GlanceProps) => {
  const createROIMetric = (name: string, roi: SymbolROI, period: number) => {
    return (
      <Metric
        name={name}
        value={toPercent(roi.annualizedTotalROIWithDRIP.value)}
        quality={roi.annualizedTotalROIWithDRIP.connotation}
        context={(
          <PeriodReturn periodYear={period} period={roi} />
        )}
      />
    );
  }
  return (
    <div className="tc-stock-summary-glance">
      {isMulti && (
        <div className="tc-stock-summary-glance-symbol">
          {report.symbol}
        </div>
      )}
      <div className="tc-stock-summary-glance-symbol-metrics">
        {createROIMetric('1Y return', report.roi.y1, 1)}
        {createROIMetric('5Y annual', report.roi.y5, 5)}
        <Metric
          name='Forward yield'
          value={toPercent(report.roi.y1.forwardDividendYield.value)}
          quality={report.roi.y1.forwardDividendYield.connotation}
        />
        {_.get(report, ['earnings', 'revenue']) && (
          <Metric
            name='Revenue'
            value={toBigMoney(report.earnings.revenue.value)}
            quality={report.earnings.revenue.connotation}
            context={(
              <Explainer>
                <>
                  <ExplainerSection name='Breakdown' />
                  <ExplainerItem name='Revenue' value={toMoneyLong(report.earnings.revenue.value)} />
                  <ExplainerItem name='EBIT' value={toMoneyLong(report.earnings.ebit.value)} />
                  <ExplainerItem name='EBITDA' value={toMoneyLong(report.earnings.ebitda.value)} />
                  <ExplainerItem name='Net Income' value={toMoneyLong(report.earnings.netIncome.value)} accountingLine />
                  <ExplainerNote note={`Reported on the annual income statement for period ending ${report.earnings.date}`} />
                </>
              </Explainer>
            )}
          />
        )}
        {_.get(report, ['riskRatios', 'netProfitMargin']) && (
          <Metric
            name='Net Margin'
            value={toPercent(report.riskRatios.netProfitMargin.value)}
            quality={report.riskRatios.netProfitMargin.connotation}
            context={(
              <Explainer>
                <>
                  <ExplainerSection name='Breakdown' />
                  <ExplainerItem name='Gross margin' value={toPercent(report.riskRatios.grossMargin.value)} />
                  <ExplainerItem name='Operating margin' value={toPercent(report.riskRatios.operatingMargin.value)} />
                  <ExplainerItem name='Net profit margin' value={toPercent(report.riskRatios.netProfitMargin.value)} accountingLine />
                  <ExplainerNote note={`Reported on the annual income statement for period ending ${report.earnings.date}`} />
                </>
              </Explainer>
            )}
          />
        )}
        {_.get(report, ['riskRatios', 'debtServiceCoverage']) && (
          <Metric
            name='Debt Cov.'
            value={`${Math.round(report.riskRatios.debtServiceCoverage.value)}x`}
            quality={report.riskRatios.debtServiceCoverage.connotation}
          />
        )}
        {_.get(report, ['priceRatios', 'peRatio']) && (
          <Metric
            name='P/E'
            value={`${Math.round(report.priceRatios.peRatio.value)}x`}
            quality={report.priceRatios.peRatio.connotation}
          />
        )}
        {_.get(report, ['summary', 'beta']) && (
          <Metric
            name='Beta'
            value={`${report.summary.beta.value}`}
            quality={report.summary.beta.connotation}
          />
        )}
        {_.get(report, ['riskRatios', 'freeCashFlowYield']) && (
          <Metric
            name='FCF Yield'
            value={toPercent(report.riskRatios.freeCashFlowYield.value / 100)}
            quality={report.riskRatios.freeCashFlowYield.connotation}
          />
        )}
        {_.get(report, ['dilution', 'dilution5yShare']) && (
          <Metric
            name='5Y Dilution'
            value={toPercent(1 - report.dilution.dilution5yShare.value)}
            quality={report.dilution.dilution5yShare.connotation}
          />
        )}
      </div>
    </div>
  );
}
