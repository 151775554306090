import { Tooltip } from "@mui/material";
import { formatDollars, toMoney } from "../format/format";
import './price-dial.css';

export interface PriceDialProps {
  low: number;
  high: number;
  current: number;
}

export const PriceDial = ({ low, high, current }: PriceDialProps) => {
  const knobPosition = ((current - low) / (high - low)) * 100;
  const tooltip = `Current price: ${formatDollars(current)}`;

  return (
    <div className='tc-price-dial'>
      <div className='tc-price-dial-indicator'>
        <div className='tc-price-dial-indicator-track' />
        <Tooltip title={tooltip} content={tooltip} placement="top">
          <div className='tc-price-dial-indicator-knob' style={{ left: `${knobPosition}%` }} />
        </Tooltip>
      </div>
      <div className='tc-price-dial-text'>
        <div className='tc-price-dial-text-low'>
          <strong className='tc-price-dial-text-headline'>
            5Y low
          </strong>
          {toMoney(low)}
        </div>
        <div className='tc-price-dial-text-high'>
          <strong className='tc-price-dial-text-headline'>
            5Y high
          </strong>
          {toMoney(high)}
        </div>
      </div>
    </div>
  );
}
