import { Hash } from "../../api";
import * as _ from 'lodash';

const saltPrefix = 'TRAINEDCAPITAL';
const argon2 = require('argon2-browser');

export const hashPassword = async (password: string, email: string): Promise<Hash> => {
  const salt = `${saltPrefix}::${email}`;
  const hash = await argon2.hash({
    pass: password,
    salt
  });
  return {
    hash: _.get(hash, ['hashHex'], null),
    salt,
    algorithm: 'argon2'
  };
}
