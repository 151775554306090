import * as React from 'react';
import { Card, CardContent, Typography } from "@mui/material";
import _ from "lodash";
import { ChartPreview, ChartSeries } from "../../../../utils/chart-preview/chart-preview";
import { Connotation } from "../../../../utils/connote/connote";
import { toMoney, toPercent } from "../../../../utils/format/format";
import { SummaryReports } from '../../../stock-types';
import { Legend } from '../../../../utils/color/legend';
import { flattenStructure } from '../../../../utils/chart-preview/utils';

export interface GlanceProps {
  reports: SummaryReports;
}

export const TotalReturnCard = ({ reports }: GlanceProps) => {
  const isSingular = reports.length === 1;

  const series: ChartSeries[] = _.flatten(_.map(reports, report => {
    const firstSummary = reports[0].summary;
    const symbol = report.summary.symbol;
    const showPrice = isSingular && firstSummary.dividend.paidRecently;
    const priceSeries = {
      key: `${symbol}-stockValue`,
      connotation: Connotation.NEUTRAL,
      stroke: 1,
      kicker: 'Withdrawing dividends'
    }
    const priceSeriesToAdd = showPrice ? [ priceSeries ] : [];
    const partialSeries: ChartSeries[] = [
      {
        key: `${symbol}-DRIPValue`,
        connotation: (firstSummary.totalReturnSim.annualizedReturn > 0) ? Connotation.GREAT : Connotation.TERRIBLE,
        stroke: 4,
        kicker: isSingular ? 'Reinvesting dividends' : `$${symbol}`,
        color: isSingular ? undefined : report.color
      },
      ...priceSeriesToAdd
    ];
    return partialSeries;
  }));

  // Merge data into a flat structure
  const keyedSims = _.keyBy(reports, report => report.summary.symbol);
  const allSims = _.mapValues(keyedSims, report => _.reverse(_.cloneDeep(report.summary.totalReturnSim.valueHistory)));
  const merged = flattenStructure(allSims);

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" color="text.secondary">
          Total Return
        </Typography>
        <div className='tc-summary-chart-wrap'>
          <ChartPreview
            data={merged}
            series={series}
            format='money'
          />
        </div>
        {_.map(reports, report => (
          <Typography variant='body2' gutterBottom key={report.summary.symbol}>
            Investing
            {' '}<strong>{toMoney(report.summary.totalReturnSim.startingInvestment)}</strong>{' '}
            into <strong>${report.summary.symbol}</strong>{' '}{!isSingular && <Legend color={report.color} />}{' '}
            and reinvesting dividends would have generated a
            {' '}<strong>{toPercent(report.summary.totalReturnSim.annualizedReturn)}</strong>{' '}
            annualized return over the previous 5 years. Out of total profits, 
            {' '}<strong>{toPercent(report.summary.totalReturnSim.percentFromDividends)}</strong>{' '}
            came from dividends.
          </Typography>
        ))}
      </CardContent>
    </Card>
  );
}
