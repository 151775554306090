import { Card, CardContent, Typography } from "@mui/material";
import * as _ from "lodash";
import './catastrophic.css';
import { AssessedRisk } from "../../../api";
import GroupIcon from '@mui/icons-material/Group';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import PublicIcon from '@mui/icons-material/Public';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import classNames from "classnames";
import { RiskReports } from "../../stock-types";

const keyNames: { [key: string]: string } = {
  decliningPopulation: 'Declining Population',
  decliningDollar: 'Declining Dollar',
  artificialIntelligence: 'Artificial Intelligence',
  climateRisk: 'Climate Risk',
  economicRecession: 'Economic Recession',
  cybersecurityRisk: 'Infrastructure Risk',
  pandemicRisk: 'Pandemic Risk',
  warRisk: 'War Risk',
};

const keyConnotationText: { [key: string]: string } = {
  'GOOD': 'Outperform',
  'BAD': 'Underperform'
};

const keyIcons: { [key: string]: any } = {
  decliningPopulation: <GroupIcon />,
  decliningDollar: <MoneyOffIcon />,
  artificialIntelligence: <SmartToyIcon />,
  economicRecession: <AccountBalanceIcon />,
  climateRisk: <PublicIcon />,
  cybersecurityRisk: <GppMaybeIcon />,
  pandemicRisk: <CoronavirusIcon />,
  warRisk: <MilitaryTechIcon />
}

export interface CatastrophicRiskItemProps {
  assessedRisk: AssessedRisk;
}

export const CatastrophicRiskItem = ({ assessedRisk }: CatastrophicRiskItemProps) => {
  const isGood = assessedRisk.connotation === 'GOOD';
  const className = classNames({
    'tc-risk-catastrophic-item': true,
    'tc-risk-catastrophic-item-upside': isGood,
    'tc-risk-catastrophic-item-downside': !isGood,
    'tc-risk-catastrophic-item-downside-medium': !isGood && assessedRisk.volatility === 'MEDIUM',
    'tc-risk-catastrophic-item-downside-extreme': !isGood && assessedRisk.volatility === 'EXTREME',
  });
  return (
    <div className={className}>
      <div className='tc-risk-catastrophic-item-name'>
        <strong>{keyNames[assessedRisk.riskId] as string}</strong>
        <span className='tc-risk-catastrophic-item-indication'>
          {isGood && (
            <>
              (Outperform: {_.capitalize(assessedRisk.volatility)})
            </>
          )}
          {!isGood && (
            <>
              ({_.capitalize(assessedRisk.volatility)})
            </>
          )}
        </span>
      </div>
      <div className='tc-risk-catastrophic-item-reason'>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {assessedRisk.reason}
        </Typography>
      </div>
    </div>
  );
}

export interface CatastrophicRiskProps {
  reports: RiskReports;
}

export const CatastrophicRisk = ({ reports }: CatastrophicRiskProps) => {
  const className = classNames({
    'tc-risk-catastrophic-wrap': true,
    'tc-risk-catastrophic-wrap-multiple': reports.length > 1
  });
  return (
    <div className={className}>
      {_.map(reports, report => (
        <div className='tc-risk-catastrophic' key={report.symbol}>
          <Card>
            <CardContent>
              <Typography variant="h5" color="text.secondary" gutterBottom>
                {reports.length > 1 && (<>${report.symbol}:</>)}{' '}
                Catastrophic Risk
              </Typography>
              {_.map(report.risk.catastrophicRisk.risks, (risk: AssessedRisk) => (
                <CatastrophicRiskItem
                  key={risk.riskId}
                  assessedRisk={risk}
                />
              ))}
            </CardContent>
          </Card>
        </div>
      ))}
    </div>
  );
}
