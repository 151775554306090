import { Checkbox, FormControlLabel, IconButton, Popover, TextField, Tooltip, Typography } from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox';
import './title.css';
import React from "react";
import { RiskReports, SummaryReports } from "../stock-types";
import { Compare } from "../compare/compare";
import { Watch } from "../watch/watch";
import { StockMobileMenu } from "../mobile-menu/stock-mobile-menu";

export interface StockTitleParams {
  name: string;
  symbols: string[];
  reports: SummaryReports | RiskReports | null;
}

export const StockTitle = ({ name, reports, symbols }: StockTitleParams) => {
  return (
    <div className='tc-container'>
      <div className='tc-stock-header'>
        <div className='tc-stock-title'>
          <Typography variant="h1" gutterBottom>
            {name}
          </Typography>
        </div>
        <Compare symbols={symbols} reports={reports} />
        <StockMobileMenu symbols={symbols} reports={reports} />
        <div className='tc-stock-header-stretch' />
        <div className='tc-stock-header-controls'>
          <Watch symbols={symbols} reports={reports} />
        </div>
      </div>
    </div>
  );
}
