import * as React from 'react';
import { useReactiveVar } from "@apollo/client";
import { Button, Menu } from "@mui/material";
import { authVar } from "../../../state/auth";
import { Link } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SignOut } from './signout';
import './header-login.css';

export const HeaderLogin = () => {
  const auth = useReactiveVar(authVar);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (auth.status === 'guest') {
    return (
      <div className='tc-header-desktop-auth'>
        <div className='tc-header-desktop-auth-item'>
          <Button color="primary" component={Link} to='/signup' variant='contained'>
            Sign up
          </Button>
        </div>
        <div className='tc-header-desktop-auth-item'>
          <Button color="primary" component={Link} to='/signin'>
            Log in
          </Button>
        </div>
      </div>
    );
  }

  if (auth.status === 'user' && auth.login) {
    return (
      <div className='tc-header-desktop-auth'>
        <Button endIcon={<KeyboardArrowDownIcon />} onClick={handleClick}>
          {auth.login.name}
        </Button>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <SignOut />
        </Menu>
      </div>
    );
  }

  return null;
};
