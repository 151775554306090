import { Alert, Button, Card, CardContent, TextField, Typography } from "@mui/material";
import './signup.css';
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import * as _ from 'lodash';
import { hashPassword } from "../utils/auth/password";
import { useNavigate } from "react-router";
import { completeLogin } from "../utils/auth/login";

const SignUpMutation = gql`
mutation signUp($input: SignUpInput!) {
  signUp(input: $input) {
    success
    login {
      id
      user
      name
      admin
      premium
    }
  }
}
`;

const ERROR_NAME_MUST_BE_SPECIFIED = 'Name must be specified';
const ERROR_EMAIL_MUST_BE_SPECIFIED = 'Email must be specified';
const ERROR_PASSWORD_INSUFFICIENT_LENGTH = 'Password must be 8 characters long';
const ERROR_PASSWORD_MUST_MATCH = 'Password and confirmation must match';

export const Signup = () => {
  const [ emailAddress, setEmailAddress ] = useState('');
  const [ name, setName ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ confirmPassword, setConfirmPassword ] = useState('');
  const [ errors, setErrors ] = useState<string[]>([]);
  const [ isLoading, setLoading ] = useState(false);
  const navigate = useNavigate();
  const [ signUp, response ] = useMutation(SignUpMutation, {
    onCompleted: (data) => {
      const login = _.get(data, ['signUp', 'login']);
      if (login) {
        completeLogin(login);
        navigate('/');
      }
    }
  });

  const onSubmit = async () => {
    setLoading(true);
    let newErrors = [];
    if (password.length < 8) {
      newErrors.push(ERROR_PASSWORD_INSUFFICIENT_LENGTH);
    }
    if (!name) {
      newErrors.push(ERROR_NAME_MUST_BE_SPECIFIED);
    }
    if (!emailAddress) {
      newErrors.push(ERROR_EMAIL_MUST_BE_SPECIFIED);
    }
    if (password !== confirmPassword) {
      newErrors.push(ERROR_PASSWORD_MUST_MATCH);
    }
    if (newErrors.length === 0) {
      const hash = await hashPassword(password, emailAddress);
      signUp({
        variables: {
          input: {
            emailAddress,
            name,
            hash
          }
        }
      });
    } else {
      setErrors(newErrors);
      setLoading(false);
    }
  }

  const combinedErrors = [...errors, ..._.get(response, ['data', 'errors'], [])];

  return (
    <>
      <div className="tc-signup-form-wrap">
        <div className="tc-container">
          <Card>
            <CardContent>
              <div className='tc-signup-header'>
                <Typography variant="h5" color="text.secondary">
                  Create an Account
                </Typography>
              </div>
              <div className='tc-signup-field'>
                <TextField
                  label='Email address'
                  value={emailAddress}
                  onChange={(ev) => setEmailAddress(ev.currentTarget.value)}
                  fullWidth
                />
              </div>
              <div className='tc-signup-field'>
                <TextField
                  label='Your name'
                  helperText='This will be publicly displayed. It can be a real name or a pseudonym.'
                  value={name}
                  onChange={(ev) => setName(ev.currentTarget.value)}
                  fullWidth
                />
              </div>
              <div className='tc-signup-field'>
                <TextField
                  label='Password'
                  type='password'
                  value={password}
                  onChange={(ev) => setPassword(ev.currentTarget.value)}
                  helperText='Must be 8 characters long.'
                  fullWidth
                />
              </div>
              <div className='tc-signup-field'>
                <TextField
                  label='Confirm Password'
                  type='password'
                  value={confirmPassword}
                  onChange={(ev) => setConfirmPassword(ev.currentTarget.value)}
                  fullWidth
                />
              </div>
              {combinedErrors.map(err => (
                <div className='tc-signup-field'>
                  <Alert severity="error">{err}</Alert>
                </div>
              ))}
              <div className='tc-signup-field'>
                <Button
                  color='primary'
                  variant='contained'
                  disabled={isLoading}
                  onClick={onSubmit}
                >Sign up</Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};
