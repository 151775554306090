import { gql, useMutation, useReactiveVar } from "@apollo/client";
import { Button } from "@mui/material";
import { authVar } from "../../state/auth";
import { useState } from "react";
import { RiskReports, SummaryReports } from "../stock-types";
import * as _ from 'lodash';
import { RegisterPrompt } from "../../utils/register-prompt/register-prompt";
import { unwatchMutation, watchMutation } from "./watch-mutations";

export interface WatchProps {
  symbols: string[];
  reports: SummaryReports | RiskReports | null;
}

export const Watch = ({ symbols, reports }: WatchProps) => {
  const auth = useReactiveVar(authVar);
  const watchingAll = _.reduce(reports, (result, report) => result && _.get(report, ['watching'], false), true);
  const [ isWatching, setIsWatching ] = useState(watchingAll);
  const [ watch, watchResponse ] = useMutation(watchMutation);
  const [ unwatch, unwatchResponse ] = useMutation(unwatchMutation);

  const onWatch = () => {
    if (auth.status !== 'user') {
      return;
    }
    watch({
      variables: {
        symbols,
        token: auth.login?.id
      },
      onCompleted: () => {
        setIsWatching(true);
      }
    });
  }

  const onUnwatch = () => {
    if (auth.status !== 'user') {
      return;
    }
    unwatch({
      variables: {
        symbols,
        token: auth.login?.id
      },
      onCompleted: () => {
        setIsWatching(false);
      }
    });
  }

  const disabled = watchResponse.loading || unwatchResponse.loading;

  if (auth.status === 'guest') {
    return (
      <div className='tc-desktoponly'>
        <RegisterPrompt level='user'>
          <Button variant="contained" color='primary'>
            Add to Watchlist
          </Button>
        </RegisterPrompt>
      </div>
    );
  }

  if (isWatching) {
    return (
      <>
        <div className='tc-desktoponly'>
          <Button variant="contained" color='primary' disabled={disabled} onClick={onUnwatch}>
            Remove from Watchlist
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <div className='tc-desktoponly'>
        <Button variant="contained" color='primary' disabled={disabled} onClick={onWatch}>
          Add to Watchlist
        </Button>
      </div>
    </>
  );
}
