import * as React from 'react';
import { Card, CardContent, Typography } from "@mui/material";
import _ from "lodash";
import { ChartPreview } from "../../../../utils/chart-preview/chart-preview";
import { Connotation } from "../../../../utils/connote/connote";
import { toMoney, toPercent } from "../../../../utils/format/format";
import { SummaryReports } from '../../../stock-types';
import { flattenStructure } from '../../../../utils/chart-preview/utils';
import { Legend } from '../../../../utils/color/legend';
import './dividend-card.css';

export interface GlanceProps {
  reports: SummaryReports;
}

export const DividendCard = ({ reports }: GlanceProps) => {
  const symbolRecord = reports[0].summary;

  // Placeholder for stocks that don't pay dividends
  if (reports.length === 1 && !reports[0].summary.dividend.paidRecently) {
    let message = (!reports[0].summary.dividend.paidEver)
      ? 'never paid a dividend'
      : 'not paid a dividend recently';

    return (
      <Card>
        <CardContent>
          <Typography variant="h5" color="text.secondary">
            Dividends
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {reports[0].summary.name} has {message}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  // Card for stocks that pay dividends

  // const data = _.reverse(_.cloneDeep(symbolRecord.dividendsByMonth));
  const keyedReported = _.keyBy(reports, report => report.summary.symbol);
  const allDividends = _.mapValues(keyedReported, report => _.reverse(_.cloneDeep(report.summary.dividendsByMonth)));
  const merged = flattenStructure(allDividends);
  
  const lastFourDividends = symbolRecord.dividendsByMonth.length > 4
    ? symbolRecord.dividendsByMonth.slice(0, 4)
    : symbolRecord.dividendsByMonth;
  const firstDividend = _.last(symbolRecord.dividendsByMonth);
  const lastDividend = _.first(symbolRecord.dividendsByMonth);
  const good = (firstDividend && lastDividend) 
    ? firstDividend.dividend < lastDividend.dividend
    : false;

  const isSingular = reports.length === 1;
  const series = _.map(reports, report => ({
    key: `${report.symbol}-dividend`,
    connotation: good ? Connotation.GREAT : Connotation.TERRIBLE,
    color: isSingular ? undefined : report.color,
    kicker: `$${report.symbol}`
  }));

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" color="text.secondary">
          Dividends
        </Typography>
        <div className='tc-summary-chart-wrap'>
          <ChartPreview
            data={merged}
            series={series}
            format='money'
          />  
        </div>
        {_.map(reports, report => {
          const firstDividend = _.last(report.summary.dividendsByMonth);
          const lastDividend = _.first(report.summary.dividendsByMonth);
          const hasDividend = firstDividend && lastDividend;
          const message = (!report.summary.dividend.paidEver)
            ? 'never paid a dividend'
            : 'not paid a dividend recently';

          return (
            <div className='tc-summary-chart-dividend-item'>
              {!isSingular && (
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  <strong>${report.symbol}</strong>{' '}
                  <Legend color={report.color} />
                </Typography>
              )}
              {hasDividend && (
                <div className='tc-summary-chart-progression'>
                  <div className='tc-summary-chart-progression-begin'>
                    <strong>{firstDividend.date}</strong>
                    <span>{toMoney(firstDividend.dividend)}</span>
                  </div>
                  <div className='tc-summary-chart-progression-arrow'>
                    &rarr;
                  </div>
                  <div className='tc-summary-chart-progression-end'>
                    <strong>{lastDividend.date}</strong>
                    <span>{toMoney(lastDividend.dividend)}</span>
                  </div>
                </div>
              )}
              {!hasDividend && (
                <div className='tc-summary-chart-progression-no-dividend'>
                  {symbolRecord.name} has {message}
                </div>
              )}
            </div>
          );
        })}
        {isSingular && (_.map(lastFourDividends, (divi) => (
          <Typography variant="body2" color="text.secondary" key={divi.date} gutterBottom>
            <span className='tc-summary-charts-metric'>
              <strong>{divi.date}:</strong>
              <span>${divi.dividend} ({toPercent(divi.percent)})</span>
            </span>
          </Typography>
        )))}
        {isSingular && (
          <Typography variant="body2" color="text.secondary">
            Annualized yield: {toPercent(symbolRecord.roi.y1.annualizedDividendYield.value)}
          </Typography>
        )}
      </CardContent>
      {/*<CardActions>
        <Button size="small">More</Button>
      </CardActions>*/}
    </Card>
  );
}
