import * as React from 'react';
import './page-error.css';

export const PageError = () => {
  return (
    <div className='tc-error-page'>
      Could not load page
    </div>
  );
}
