import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import './header.css';
import { HeaderSearch } from '../search/search';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import classNames from 'classnames';
import { HeaderLogin } from './login/header-login';
import { DesktopMenu } from './desktop-menu/desktop-menu';
import { MobileMenu } from './mobile-menu/mobile-menu';
import { Watchlist } from './watchlist/watchlist';

export const Header = () => {
  const [ enableSearchOnMobile, setEnableSearchOnMobile ] = React.useState(false);

  const enableSearch = () => {
    setEnableSearchOnMobile(true);
  }

  const searchClassName = classNames({
    'tc-desktoponly': !enableSearchOnMobile
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <MobileMenu />
          <div className='tc-logo-wrap'>
            <Link to='/'><img src='/images/logo.png' className='tc-logo' /></Link>
            {!enableSearchOnMobile && (
              <Typography variant="h6" component="div" className='tc-logo-text tc-desktoponly'>
                <Link to='/' className='tc-logo-text'>Trained Capital</Link>
              </Typography>
            )}
          </div>
          <div className={searchClassName}>
            <HeaderSearch />
          </div>
          <DesktopMenu />
          <div className='tc-header-stetch'></div>
          {!enableSearchOnMobile && (
            <div className='tc-mobileonly tc-search-enable'>
              <IconButton aria-label="search" color="secondary" onClick={enableSearch}>
                <SearchIcon />
              </IconButton>
            </div>
          )}
          <Watchlist />
          <HeaderLogin />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
