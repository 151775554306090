import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#c0ca33',
    },
    secondary: {
      main: '#edf2ff',
    },
    text: {
      primary: "#ffffff", // or any color you want
      secondary: "#ffffffbb" // or any color you want
    }
  },
  typography: {
    allVariants: {
      color: "white"
    },
    h1: {
      fontSize: '2rem'
    },
    h3: {
      fontSize: '1.5rem'
    },
    h4: {
      fontSize: '1rem'
    }
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#ffffff',
          '&.Mui-selected': {
            color: '#ffffff'
          }
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.MuiTypography-root.MuiTypography-h1.MuiTypography-gutterBottom': {
            marginBottom: '0'
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'black'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          textTransform: 'none',
          fontWeight: 'bold'
        }
      }
    }
  },
});
