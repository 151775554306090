import * as React from 'react';
import classnames from 'classnames';
import './explainer-section.css';

export interface ExplainerItemProps {
  name: string;
}

export const ExplainerSection = ({ name }: ExplainerItemProps) => {
  const classNames = classnames({
    "tc-explainer-section": true
  });

  return (
    <div className={classNames}>
      {name}
    </div>
  );
}
