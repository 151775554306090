
export interface DateTimeProps {
  when: string;
}

export const DateTime = ({ when }: DateTimeProps) => {
  const date = new Date(Number(when));
  const formattedDate = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  return (
    <>
      {formattedDate}
    </>
  );
}
