
export enum Connotation {
  GREAT = 'GREAT',
  GOOD = 'GOOD',
  NEUTRAL = 'NEUTRAL',
  BAD = 'BAD',
  TERRIBLE = 'TERRIBLE'
}

export const getConnotationFromPercent = (num: number, bound = 0.05): Connotation => {
  if (num >= bound) {
    return Connotation.GREAT;
  } else if (num <= bound && num > 0) {
    return Connotation.GOOD
  } else if (num === 0) {
    return Connotation.NEUTRAL;
  } else if (num >= -bound) {
    return Connotation.BAD;
  } else {
    return Connotation.TERRIBLE;
  }
}
