import { SummaryReport } from "../../../api";
import * as _ from 'lodash';
import './charts.css';
import { DividendCard } from "./dividend/dividend-card";
import { PriceCard } from "./price/price-card";
import { TotalReturnCard } from "./total-return/total-return";
import { SummaryReports } from "../../stock-types";
import { DispersalCard } from "./dispersal/dispersal-card";
import { DilutionCard } from "./dilution/dilution-card";

export interface GlanceProps {
  reports: SummaryReports;
}

export const SummaryCharts = ({ reports }: GlanceProps) => {
  return (
    <div className='tc-summary-charts'>
      <div className='tc-summary-charts-top'>
        <TotalReturnCard reports={reports} />
      </div>
      <div className='tc-summary-charts-columns'>
        <div className='tc-summary-charts-column1'>
          <DividendCard reports={reports} />
          <div className='tc-summary-charts-column-item'>
            <DispersalCard reports={reports} />
          </div>
        </div>
        <div className='tc-summary-charts-column2'>
          <PriceCard reports={reports} />
          <div className='tc-summary-charts-column-item'>
            <DilutionCard reports={reports} />
          </div>
        </div>
      </div>
    </div>
  );
}
