import { Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import * as _ from 'lodash';
import { DirectoryItem } from "../api";
import { Link, useNavigate } from "react-router-dom";
import './directory.css';

export interface DirectoryCardProps {
  directory: DirectoryItem[];
}

export const DirectoryCard = ({ directory }: DirectoryCardProps) => {
  const navigate = useNavigate();
  const onClick = (item: DirectoryItem) => () => {
    navigate(item.link);
  }
  return (
    <div className="tc-browse-DirectoryCard-wrap">
      <Card>
        <CardContent>
          <Typography variant="h3" color="text.secondary" gutterBottom>
            Directory
          </Typography>
          <Typography variant="body2" color="text.secondary">
            There are currently{' '}
            <strong>{directory.length}</strong>
            {' '}stocks in our database.
          </Typography>
          <Table>
            <TableHead>
              <TableCell width={100}>Symbol</TableCell>
              <TableCell>Name</TableCell>
            </TableHead>
            <TableBody>
              {_.map(directory, item => (
                <TableRow
                  className='tc-browse-DirectoryCard-tbody-cell'
                  hover
                  onClick={onClick(item)}
                  key={item.symbol}
                >
                  <TableCell>
                    <Link to={item.link}>{item.symbol}</Link>
                  </TableCell>
                  <TableCell>
                    {item.name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
}
