import { makeVar } from '@apollo/client';
import { LoginCompletion } from '../api';

export interface AuthState {
  status: 'loading' | 'guest' | 'user';
  login: LoginCompletion | null;
}

const defaultAuthState: AuthState = {
  status: 'loading',
  login: null
};

export const authVar = makeVar<AuthState>(defaultAuthState);

export const setAuthUser = (login: LoginCompletion) => {
  authVar({
    status: 'user',
    login
  });
}

export const setAuthGuest = () => {
  authVar({
    status: 'guest',
    login: null
  });
}

export const isReady = (auth: AuthState) => {
  return auth.status === 'user' || auth.status === 'guest';
}
