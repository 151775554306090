import { Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import * as _ from 'lodash';
import { ListItem } from "../../api";
import { Link, useNavigate } from "react-router-dom";
import './lists-card.css';

export interface ListsCardProps {
  lists: { [key: string]: ListItem[] };
}

export const ListsCard = ({ lists }: ListsCardProps) => {
  const navigate = useNavigate();
  const onClick = (item: ListItem) => () => {
    navigate(item.link);
  }
  return (
    <div className="tc-home-ListsCard-wrap">
      <Card>
        <CardContent>
          <Typography variant="h3" color="text.secondary">
            Featured Stocks
          </Typography>
          <Table>
            <TableHead>
              <TableCell width={100}>Symbol</TableCell>
              <TableCell>Name</TableCell>
            </TableHead>
            <TableBody>
              {_.map(lists.picks, item => (
                <TableRow
                  className='tc-home-ListsCard-tbody-cell'
                  hover
                  onClick={onClick(item)}
                  key={item.symbol}
                >
                  <TableCell>
                    <Link to={item.link}>{item.symbol}</Link>
                  </TableCell>
                  <TableCell>
                    {item.name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
}
