import { gql } from "@apollo/client";

export const mainQuery = gql`
query Symbol($symbols: [String]!, $token: String) {
  symbols(symbols: $symbols, token: $token) {
    summary {
      name
      symbol
      watching
      summary {
        description
      }
    }
  }
}
`;
