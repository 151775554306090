import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import _ from "lodash";

export interface ChartPreviewPieSeries {
  key: string;
  name: string;
  value: any;
  color?: string;
}

export interface ChartPreviewProps {
  series: ChartPreviewPieSeries[];
  tooltip?: React.ReactElement;
}

export const ChartPreviewPie = ({ series }: ChartPreviewProps) => {
  return (
    <ResponsiveContainer width="95%" height={200}>
      <PieChart width={200} height={200}>
        <Pie
          data={series}
          cx={125}
          cy={100}
          innerRadius={60}
          outerRadius={80}
          paddingAngle={10}
          stroke='#222222'
          dataKey="value"
        >
          {series.map((s) => (
            <Cell key={s.key} fill={s.color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
