import { ResponsiveContainer, LineChart, Line, YAxis, Tooltip, ReferenceLine } from "recharts";
import { Connotation } from "../connote/connote";
import './chart-preview.css';
import { toMoney, toPercent } from "../format/format";
import _ from "lodash";
import classNames from "classnames";

export interface ChartSeries {
  key: string;
  connotation: Connotation;
  stroke?: number;
  kicker?: string;
  color?: string;
}

export interface ChartPreviewProps {
  data: any;
  series: ChartSeries[];
  tooltip?: React.ReactElement;
  format: string;
  showReference?: boolean;
}

const renderFormat = (value: number, format: string) => {
  if (format === 'money') {
    return toMoney(value);
  } else if (format === 'ratio') {
    return value.toFixed(3);
  } else if (format === 'percent') {
    return toPercent(value);
  }
  return value;
}

const CustomTooltip = ({ active, payload, label, data, series, format }: any) => {
  if (active && payload && payload.length) {
    console.log({ payload });
    const date = payload[0].payload.date;
    const count = payload.length;
    const className = classNames({
      'tc-chart-preview-tooltip': true,
      'tc-chart-preview-tooltip-multiple': count > 1,
      'tc-chart-preview-tooltip-large': count > 2,
    });
    return (
      <div className={className}>
        {_.map(payload, (p, k) => (
          <div key={k}>
            {series[k].kicker && (
              <span className='tc-chart-preview-tooltip-kicker'>
                {series[k].kicker}
              </span>
            )}
            <strong>{renderFormat(p.value, format)}</strong>
            <span>{date}</span>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

const getColor = (connotation: Connotation) => {
  if (connotation === Connotation.GREAT || connotation === Connotation.GOOD) {
    return '#43a047';
  }
  if (connotation === Connotation.BAD || connotation === Connotation.TERRIBLE) {
    return '#b71c1c';
  }
  return '#888888';
}

export const ChartPreview = ({ data, series, format, showReference = true }: ChartPreviewProps) => {
  return (
    <ResponsiveContainer width="95%" height={100}>
      <LineChart width={200} height={10} data={data}>
        {_.map(series, s => {
          return (
            <Line
              key={s.key}
              type="monotone"
              dataKey={s.key}
              stroke={s.color || getColor(s.connotation)}
              strokeWidth={s.stroke || 4}
              dot={false}
            />
          );
        })}
        <YAxis domain={['dataMin', 'dataMax']} hide />
        <Tooltip content={<CustomTooltip data={data} series={series} format={format} />} />
        {showReference && <ReferenceLine y={0} stroke="#888888" />}
      </LineChart>
    </ResponsiveContainer>
  );
}
