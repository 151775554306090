import { gql } from "@apollo/client";

export const watchMutation = gql`
mutation watch($symbols: [String]!, $token: String!) {
  watch(symbols: $symbols, token: $token) {
    symbol
    name
  }
}
`;

export const unwatchMutation = gql`
mutation unwatch($symbols: [String]!, $token: String!) {
  unwatch(symbols: $symbols, token: $token)
}
`;
