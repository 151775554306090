import { gql } from "@apollo/client";

export const riskQuery = gql`
fragment MetricFragment on Metric {
  value
  connotation
  best
}

query Symbol($symbols: [String]!, $token: String) {
  symbols(symbols: $symbols, token: $token) {
    symbols {
      symbol
      color
      watching
      risk {
        name
        symbol
        riskRatios {
          date
          debtServiceCoverage { ...MetricFragment }
          interestCoverage { ...MetricFragment }
          current { ...MetricFragment }
          debtToEquity { ...MetricFragment }
          grossMargin { ...MetricFragment }
          operatingMargin { ...MetricFragment }
          netProfitMargin { ...MetricFragment }
        }
        ratioHistory {
          date
          debtServiceCoverage
          interestCoverage
          current
          debtToEquity
          grossMargin
          operatingMargin
          netProfitMargin
        }
        topRisks {
          riskName
          riskIcon
          riskDescription
          connotation
          volatility
        }
        catastrophicRisk {
          risks {
            riskId
            reason
            connotation
            volatility
          }
        }
        lastUpdated
      }
    }
  }
}
`;
