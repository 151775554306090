import { Route, Routes, useParams } from "react-router-dom";
import { StockPage } from "../stock/stock";
import { HomePage } from "../home/home";
import { Signup } from "../signup/signup";
import { Signin } from "../signin/signin";
import { Browse } from "../browse/browse";

export const BaseRouter = () => {
  const params = useParams();
  const path = params.path
  if (path?.startsWith('$')) {
    return <StockPage />
  }
  return null;
}

export const RouteRoot = () => {
  return (
    <Routes>
      <Route path="/browse" element={<Browse />} />
      <Route path="/signin" element={<Signin />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/:path/:subpage" element={<BaseRouter />} />
      <Route path="/:path" element={<BaseRouter />} />
      <Route path='/' element={<HomePage />} />
    </Routes>
  );
}
