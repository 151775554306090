import { Popover } from '@mui/material';
import { Connotation } from '../connote/connote';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './metric.css';
import React from 'react';

const qualityClassPrefix = 'tc-stock-metric-quality';
const QualityClassMap: { [key: string]: string } = {
  [Connotation.GREAT]: `${qualityClassPrefix}-great`,
  [Connotation.GOOD]: `${qualityClassPrefix}-good`,
  [Connotation.NEUTRAL]: `${qualityClassPrefix}-neutral`,
  [Connotation.BAD]: `${qualityClassPrefix}-bad`,
  [Connotation.TERRIBLE]: `${qualityClassPrefix}-terrible`,
}

export interface MetricProps {
  name: string;
  value: string;
  quality: string;
  context?: React.ReactElement;
}

export const Metric = ({ name, value, quality, context }: MetricProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <div
        className={`tc-stock-metric ${QualityClassMap[quality]}`}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <div className="tc-stock-metric-value">
          {value}
        </div>
        <div className="tc-stock-metric-name">
          {name}
          {context && (
            <span className='tc-stock-metric-name-icon'>
              <InfoOutlinedIcon fontSize='inherit' />
            </span>
          )}
        </div>
      </div>
      {context && (
        <Popover
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          {context}
        </Popover>
      )}
    </>
  );
}
