import { SummaryCharts } from "./charts/charts";
import { DescriptionCard } from "./description/description";
import { DisclaimerCard } from "../../utils/disclaimer/disclaimer";
import { SummaryGlance } from "./glance/glance";
import './summary.css';
import { SummaryReports } from "../stock-types";
import * as _ from 'lodash';
import { GlanceMulti } from "./glance/glance-multi";

export interface SummarySubpageProps {
  reports: SummaryReports;
}

export const SummarySubpage = ({ reports }: SummarySubpageProps) => {
  return (
    <div className='tc-container'>
      <div className='tc-stock-summary'>
        <GlanceMulti reports={reports} />
        <div className="tc-stock-summary-main">
          <div className='tc-stock-summary-column-charts'>
            <SummaryCharts reports={reports} />
          </div>
          <div className='tc-stock-summary-column-about'>
            <DescriptionCard reports={reports} />
            <DisclaimerCard reports={reports} />
          </div>
        </div>
      </div>
    </div>
  );
}
