import React from "react";
import { Button, Popover, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import './register-prompt.css';

export interface RegisterPromptProps {
  children: any;
  level: string;
}

export const RegisterPrompt = ({ children }: RegisterPromptProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <div onClick={handleClick}>
        {children}
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2 }} gutterBottom>
          This feature is only available to logged in users.
        </Typography>
        <div className='tc-RegisterPrompt-buttons'>
          <div className='tc-RegisterPrompt-button'>
            <Button
              component={Link}
              to='/signup'
              variant="contained"
              color='primary'
              fullWidth
            >
              Sign up
            </Button>
          </div>
          <div className='tc-RegisterPrompt-button'>
            <Button
              component={Link}
              to='/signin'
              color='primary'
              fullWidth
            >
              Log in
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
}
