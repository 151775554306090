import { styled } from '@mui/material/styles';
import { Autocomplete, Box, Grid, InputBase, alpha } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import BarChartIcon from '@mui/icons-material/BarChart';
import { useLocation, useNavigate } from 'react-router-dom';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(1),
  width: '100%',
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(4),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '25ch',
    },
  },
}));

const SearchQuery = gql`
query Search($query: String!) {
  search(query: $query) {
    items {
      symbol
      name
      path
    }
  }
}
`;

export const HeaderSearch = () => {
  const nav = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  let cashtag = '';
  if (pathname.startsWith('/$')) {
    const pieces = pathname.split('/');
    cashtag = pieces[1];
  }

  const [ value, setValue ] = useState(null);
  const [ inputValue, setInputValue ] = useState('');
  const { data, loading, error } = useQuery(SearchQuery, {
    variables: {
      query: inputValue
    },
    skip: !inputValue
  });

  const options = data?.search?.items || [];

  let noOptionsText;
  if (loading) {
    noOptionsText = 'Searching...';
  } else if (inputValue) {
    noOptionsText = 'No results found in our database';
  } else {
    noOptionsText = 'Examples: KMB, INTC, PFE'
  }

  return (
    <Autocomplete
      value={value}
      options={options}
      getOptionLabel={(option: any) => option ? `$${option.symbol}` : cashtag}
      filterOptions={(x) => x}
      autoComplete
      filterSelectedOptions
      onChange={(event: any, newValue: any) => {
        setValue(newValue);
        if (newValue) {
          nav(newValue.path);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      noOptionsText={noOptionsText}
      renderInput={(params) => (
        <Search ref={params.InputProps.ref}>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder={cashtag || "Stock symbol or name..."}
            inputProps={{ 
              'aria-label': 'search', 
              ...params.inputProps
            }}
          />
        </Search>
      )}
      renderOption={(props, option: any) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <BarChartIcon />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                <Box
                  key={option.symbol}
                  component="span"
                >
                  <strong>{option.symbol}</strong> —
                  {option.name}
                </Box>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
