import { DisclaimerCard } from "../../utils/disclaimer/disclaimer";
import { RiskChart } from "./charts/risk-chart";
import * as _ from 'lodash';
import './risk.css';
import { toMoney } from "../../utils/format/format";
import { CatastrophicRisk } from "./catastrophic/catastrophic";
import { TopRisks } from "./top/top-risks";
import { RiskReports } from "../stock-types";
import { Alert } from "@mui/material";

const hasOneNegative = (nums: any[]) => {
  console.log(nums);
  return Boolean(_.find(nums, num => num < 0));
}

export interface RiskSubpageProps {
  reports: RiskReports;
}

export const RiskSubpage = ({ reports }: RiskSubpageProps) => {
  const negativeDE = hasOneNegative(_.map(reports, report => _.get(report, ['risk', 'riskRatios', 'debtToEquity', 'value'], null)));
  return (
    <div className='tc-container'>
      <div className='tc-stock-summary'>
        <div className="tc-stock-risk-main">
          <div className='tc-stock-risk-column-charts'>
            <RiskChart
              title='Current Ratio'
              reports={_.cloneDeep(reports)}
              dataKey='current'
              value={(report) => _.get(report, ['riskRatios', 'current'], null)}
              description={(period, name, value) => (
                <>
                  In the year ending {period}, {name} had
                  {' '}<strong>{toMoney(value)}</strong>{' '}
                  of liquid assets for every dollar of liabilities due that year
                </>
              )}
            />
            <RiskChart
              title='Debt Service Coverage'
              reports={_.cloneDeep(reports)}
              dataKey='debtServiceCoverage'
              value={(report) => _.get(report, ['riskRatios', 'debtServiceCoverage'], null)}
              description={(period, name, value) => (
                <>
                  In the year ending {period}, {name} made{' '}<strong>{toMoney(value)}</strong>{' '}
                  in operating income for every dollar it spent on interest &amp; debt
                </>
              )}
            />
            <RiskChart
              title='Debt to Equity'
              reports={_.cloneDeep(reports)}
              dataKey='debtToEquity'
              value={(report) => _.get(report, ['riskRatios', 'debtToEquity'], null)}
              description={(period, name, value) => (
                <>
                  In the year ending {period}, {name} used
                  {' '}<strong>{toMoney(value)}</strong>{' '}
                  in debt to finance its operations for every dollar of equity.
                  Equity financing involves selling stock or reinvesting earnings.
                </>
              )}
              helperText={negativeDE ? (
                <Alert severity="error">
                  <strong>Warning:</strong>{' '}
                  A negative debt-to-equity ratio can indicate bankrupcty or financal distress. It means the company
                  has negative equity (more debt than assets).
                </Alert>
              ) : undefined}
            />
          </div>
          <div className='tc-stock-risk-column-details'>
            <TopRisks reports={_.cloneDeep(reports)} />
            <CatastrophicRisk reports={_.cloneDeep(reports)} />
            <DisclaimerCard reports={_.cloneDeep(reports)} />
          </div>
        </div>
      </div>
    </div>
  );
}
