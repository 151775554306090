import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { useParams } from "react-router-dom";
import { StockTabs } from "./tabs/tabs";
import { StockTitle } from "./title/title";
import { SummarySubpage } from "./summary/summary";
import { FullPageLoading } from "../utils/loading/loading";
import { useEffect, useState } from "react";
import * as _ from 'lodash';
import { summaryQuery } from "./queries/summary-query";
import { riskQuery } from "./queries/risk-query";
import { mainQuery } from "./queries/main-query";
import { RiskSubpage } from "./risk/risk";
import { RiskReports, SummaryReports } from "./stock-types";
import { PageError } from "../utils/error/page-error";
import { authVar } from "../state/auth";

const selectQuery = (subpage: string) => {
  if (subpage === 'summary') {
    return summaryQuery;
  } else if (subpage === 'risk') {
    return riskQuery;
  }
  return mainQuery;
}

const selectObject = (subpage: string, data: any): SummaryReports | RiskReports | null => {
  if (!data || !data.symbols) {
    return null;
  }
  if (subpage === 'summary' || subpage === 'risk') {
    return data.symbols.symbols;
  }
  return null;
}

const getName = (data: any) => {
  if (!data || !data.symbols) {
    return null;
  }

  const arr = _.keys(_.get(data, ['symbols', 'symbols'], {}));
  const names: string[] = [];
  for (let i = 0; i < arr.length; i++) {
    const keys = _.keys(_.get(data, ['symbols', 'symbols', i], {}));
    for (let j = 0; j < keys.length; j++) {
      const maybeName = _.get(data, ['symbols', 'symbols', i, keys[j], 'name'], null);
      if (maybeName) {
        names.push(maybeName);
      }
    }
  }
  if (names.length < 1) {
    return null;
  }
  return names.join(', ');
}

export const StockPageTabs = ({ reports, subpage, loading }: { reports: SummaryReports | RiskReports, subpage: string, loading: boolean }) => {
  const summary = subpage === 'summary';
  const risk = subpage === 'risk';

  if (loading) {
    return <FullPageLoading />
  }

  if (!reports && !loading) {
    return (
      <PageError />
    );
  }

  return (
    <>
      {summary && <SummarySubpage reports={reports as SummaryReports} />}
      {risk && <RiskSubpage reports={reports as RiskReports} />}
    </>
  );
};

const parseSymbols = (symbolString: string | undefined): [string[], string, string] => {
  if (!symbolString) {
    return [[], '', '/'];
  }
  const symbolsUnparsed = symbolString.split(',');
  const list = _.map(symbolsUnparsed, unparsed => unparsed.trim().substring(1));
  const formatted = _.map(list, symbol => `$${symbol}`).join(', ');
  const path = _.map(list, symbol => `$${symbol}`).join(',');
  return [list, formatted, `/${path}`];
};

const makeTitle = (symbols: string[], name: string, symbolsString: string) => {
  if (symbols.length === 0) {
    return '';
  } else if (symbols.length === 1) {
    return `${symbols[0]}: ${name}`;
  }
  return symbolsString;
};

export const StockPage = () => {
  const params = useParams();
  const auth = useReactiveVar(authVar);
  const [ symbols, symbolsString, basePath ] = parseSymbols(params?.path);
  const subpage = params.subpage || 'summary';
  const query = selectQuery(subpage);
  const { data, loading, error } = useQuery(query, {
    variables: {
      symbols,
      token: _.get(auth, ['login', 'id'], null)
    }
  });
  const [ name, setName ] = useState<string>();

  useEffect(() => {
    const shouldBeName = getName(data);
    if (shouldBeName) {
      setName(shouldBeName);
    }
  }, [data]);

  useEffect(() => {
    if (symbols && name) {
      const titleString = makeTitle(symbols, name, symbolsString);
      document.title = `${titleString} | Trained Capital - AI Stock Analysis`;
    }
  }, [name, symbols]);

  if (!symbols) {
    return null;
  }

  if (!name) {
    return (
      <FullPageLoading />
    );
  }

  const reports = selectObject(subpage, data);
  if (error) {
    return (
      <PageError />
    );
  }

  return (
    <>
      <StockTitle name={name} symbols={symbols} reports={reports} />
      <StockTabs basePath={basePath} />
      {reports && <StockPageTabs subpage={subpage} reports={reports} loading={loading} />}
    </>
  );
}
