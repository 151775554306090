import { Card, CardContent, Typography } from "@mui/material";
import * as _ from 'lodash';
import './disclaimer.css';
import { RiskReport, SummaryReport } from "../../api";
import { DateTime } from "../datetime/datetime";
import { RiskReports, SummaryReports } from "../../stock/stock-types";

export interface DisclaimerCardProps {
  reports: SummaryReports | RiskReports;
}

export const DisclaimerCard = ({ reports }: DisclaimerCardProps) => {
  const symbolRecord = _.get(reports, [0, 'summary'], _.get(reports, [0, 'risl'], {}));
  return (
    <div className="tc-stock-summary-disclaimer">
      <Card>
        <CardContent>
          <Typography variant="body2" gutterBottom color="text.secondary">
            Last updated: {symbolRecord.lastUpdated && <DateTime when={symbolRecord.lastUpdated} />}
          </Typography>
          <Typography variant="body2" gutterBottom color="text.secondary">
            Stock data sourced from <a href='https://www.alphavantage.co/' target='_blank' rel='noopener'>AlphaVantage</a>.
            Some content generated by <a href='https://openai.com/' target='_blank' rel='noopener'>OpenAI</a>.
            Reports and analysis generated by Trained Capital's proprietary software.
          </Typography>
          <Typography variant="body2" gutterBottom color="text.secondary">
            This website does not contain any financial advice. The content presented here
            is for the purpose of better understanding this company or financial security.
            Any investment decisions you make are your own, for which you collect the full
            reward or experience the full downside.
          </Typography>
          <Typography variant="body2" color="text.secondary">
            This website is powered by experimental AI technology. We make no guarentees to the
            accuracy of this information and provide no warranties. Please further verify any
            information found here before using it to make any important decision.
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}
