import * as React from 'react';
import { Typography } from "@mui/material";
import * as _ from 'lodash';
import './description.css';
import { ExpandCard } from '../../../utils/expand-card/expand-card';
import { SummaryReports } from '../../stock-types';

export interface DescriptionCardProps {
  reports: SummaryReports;
}

export const DescriptionCard = ({ reports }: DescriptionCardProps) => {
  return (
    <>
      {_.map(reports, report => (
        <div className='tc-stock-summary-description-card-wrap' key={report.summary.symbol}>
          <ExpandCard>
            <>
              <Typography variant="h3" color="text.secondary" gutterBottom>
                {reports.length === 1 ? 'About' : report.summary.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {report.summary.summary.description}
              </Typography>
              <div className='tc-stock-summary-sketches'>
                {_.map(report.summary.sketches, (sketch: any) => (
                  <div className='tc-stock-summary-sketch' key={sketch.url}>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      <img className='tc-stock-summary-sketch-image' src={sketch.url} />
                    </Typography>
                    <Typography variant="h4" color="text.secondary" gutterBottom>
                      {sketch.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <span className='tc-stock-summary-sketch-text'>
                        {sketch.description}
                      </span>
                    </Typography>
                  </div>
                ))}
              </div>
            </>
          </ExpandCard>
        </div>
      ))}
    </>
  );
}
