import { ResponsiveContainer, BarChart, XAxis, Bar } from "recharts";
import _ from "lodash";

export interface ChartPreviewBarSegmentSeries {
  key: string;
  name: string;
  value: any;
  color?: string;
}

export interface ChartPreviewBarSegmentProps {
  series: ChartPreviewBarSegmentSeries[];
  data: any;
  tooltip?: React.ReactElement;
}

export const ChartPreviewBarSegment = ({ series, data }: ChartPreviewBarSegmentProps) => {
  return (
    <ResponsiveContainer width="95%" height={200}>
      <BarChart
        width={200}
        height={200}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="name" />
        {_.map(series, s => (
          <Bar dataKey={s.key} stackId='a' fill={s.color} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}
