import { gql, useQuery } from "@apollo/client";
import { FullPageLoading } from "../utils/loading/loading";
import { DirectoryCard } from "./directory";
import { PageError } from "../utils/error/page-error";

const browseQuery = gql`
{
  browse {
    directory {
      symbol
      name
      link
    }
  }
}
`;

export const Browse = () => {
  const { data, loading, error } = useQuery(browseQuery);

  if (loading) {
    return <FullPageLoading />
  }

  if (error || !data) {
    return (
      <PageError />
    );
  }

  return (
    <>
      <div className="tc-container">
        <DirectoryCard directory={data.browse.directory} />
      </div>
    </>
  );
}
