import './legend.css';

export interface LegendProps {
  color: string;
}

export const Legend = ({ color }: LegendProps) => {
  return (
    <div className='tc-color-legend' style={{ backgroundColor: color }}></div>
  );
}
