import { SymbolROI } from "../../../../api";
import { ExplainerItem } from "../../../../utils/explainer/item/explainer-item";
import { Explainer } from "../../../../utils/explainer/main/explainer";
import { ExplainerNote } from "../../../../utils/explainer/note/explainer-note";
import { ExplainerSection } from "../../../../utils/explainer/section/explainer-section";
import { toPercent } from "../../../../utils/format/format";

export interface PeriodReturnProps {
  period: SymbolROI;
  periodYear: number;
}

export const PeriodReturn = ({ period, periodYear }: PeriodReturnProps) => {
  const taxRate = 0.15;
  const afterTaxYield = (period.annualizedDividendYield.value * (1 - taxRate)) + period.annualizedCapitalGainROI.value;

  return (
    <Explainer>
      <>
        <ExplainerSection name='Withdrawing dividends' />
        <ExplainerItem name={`${periodYear} year capital gains`} value={toPercent(period.annualizedCapitalGainROI.value)} />
        <ExplainerItem name={`${periodYear} year dividend yield`} value={toPercent(period.annualizedDividendYield.value)} />
        <ExplainerItem name='Total return' value={toPercent(period.annualizedTotalROI.value)} accountingLine />
        <ExplainerItem name={`After-tax @ ${toPercent(taxRate)}`} value={toPercent(afterTaxYield)} />
        <ExplainerSection name='Reinvesting dividends' />
        <ExplainerItem name='Total return' value={toPercent(period.annualizedTotalROIWithDRIP.value)} />
        <ExplainerItem name='CAGR' value={toPercent(period.DRIPCAGR.value)} />
        <ExplainerNote
          note='Assuming you hold & only dividends are taxed. Sign in for detailed tax analysis.'
        />
        <ExplainerNote
          note='All percentages except for CAGR are annualized as an APR'
        />
      </>
    </Explainer>
  );
}