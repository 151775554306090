import * as React from 'react';
import { Card, CardContent, Typography } from "@mui/material";
import _ from "lodash";
import { toBigMoney, toPercent } from "../../../../utils/format/format";
import { SummaryReports } from '../../../stock-types';
import './dispersal-card.css';
import { Legend } from '../../../../utils/color/legend';
import { DispersalChart } from './dispersal-chart';
import classNames from 'classnames';

export interface GlanceProps {
  reports: SummaryReports;
}

export const DispersalCard = ({ reports }: GlanceProps) => {
  const isSingular = reports.length === 1;

  const itemClassName = classNames({
    'tc-summary-chart-dispersal-item': true,
    'tc-summary-chart-dispersal-item-singular': isSingular
  });

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" color="text.secondary">
          Profit Distribution
        </Typography>
        <div className='tc-summary-chart-wrap'>
          <DispersalChart reports={reports} />
        </div>
        <div className='tc-summary-chart-dispersal-desc'>
          <Typography variant='body2'>
            This shows who receives the company's operating profits.
          </Typography>
        </div>
        {_.map(reports, report => {
          const start: any = _.last(report.summary.priceSnapshotMonths);
          const end: any = _.first(report.summary.priceSnapshotMonths);
          return (
            <div className={itemClassName}>
              {!isSingular && (
                <div className='tc-summary-chart-dispersal-legend'>
                  <Typography variant='body2' color="text.secondary">
                    <strong>${report.symbol}</strong>
                  </Typography>
                </div>
              )}
              {!_.get(report, ['summary', 'dispersal'], false) && (
                <div className='tc-summary-chart-dispersal-nodata'>
                  <Typography variant='body2' color="text.secondary">
                    Data not available for this company
                  </Typography>
                </div>
              )}
              <div className='tc-summary-chart-dispersal-stats'>
                {Boolean(_.get(report, ['summary', 'dispersal', 'totalToEquityHolders', 'value'], false)) && (
                  <div className='tc-summary-chart-dispersal-stat'>
                    <div className='tc-summary-chart-dispersal-stat-name'>
                      <Legend color='#ff9800' />{' '}
                      To Shareholders
                    </div>
                    <div className='tc-summary-chart-dispersal-stat-value'>
                      <strong>
                        {toBigMoney(report.summary.dispersal.totalToEquityHolders.value)}
                      </strong>{' '}
                      ({toPercent(report.summary.dispersal.percentToEquityHolders.value / 100)})
                    </div>
                  </div>
                )}
                {Boolean(_.get(report, ['summary', 'dispersal', 'totalToDebtHolders', 'value'], false)) && (
                  <div className='tc-summary-chart-dispersal-stat'>
                    <div className='tc-summary-chart-dispersal-stat-name'>
                      <Legend color='#3f51b5' />{' '}
                      To Bondholders
                    </div>
                    <div className='tc-summary-chart-dispersal-stat-value'>
                      <strong>
                        {toBigMoney(report.summary.dispersal.totalToDebtHolders.value)}
                      </strong>{' '}
                      ({toPercent(report.summary.dispersal.percentToDebtHolders.value / 100)})
                    </div>
                  </div>
                )}
                {Boolean(_.get(report, ['summary', 'dispersal', 'amountReinvested', 'value'], false)) && (
                  <div className='tc-summary-chart-dispersal-stat'>
                    <div className='tc-summary-chart-dispersal-stat-name'>
                      <Legend color='#00bcd4' />{' '}
                      Reinvestment
                    </div>
                    <div className='tc-summary-chart-dispersal-stat-value'>
                      <strong>
                        {toBigMoney(report.summary.dispersal.amountReinvested.value)}
                      </strong>{' '}
                      ({toPercent(report.summary.dispersal.percentReinvested.value / 100)})
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </CardContent>
      {/*<CardActions>
        <Button size="small">More</Button>
      </CardActions>*/}
    </Card>
  );
}
