import { Card, CardContent, Typography } from "@mui/material";
import _ from "lodash";
import { ChartPreview } from "../../../utils/chart-preview/chart-preview";
import './risk-chart.css';
import { RiskReports } from "../../stock-types";
import { flattenStructure } from "../../../utils/chart-preview/utils";
import { Legend } from "../../../utils/color/legend";
import { RiskReport } from "../../../api";

export interface RiskChartProps {
  reports: RiskReports;
  title: string;
  value: (report: RiskReport) => number | null;
  description: (period: string, name: React.ReactElement, value: number) => React.ReactElement | string;
  dataKey: string;
  helperText?: React.ReactElement;
}

export const RiskChart = ({ reports, title, description, value, helperText, dataKey }: RiskChartProps) => {
  const isSingular = reports.length === 1;
  const keyedReports = _.keyBy(reports, report => report.symbol);
  const all = _.pickBy(_.mapValues(keyedReports, report => _.reverse(_.cloneDeep(report.risk.ratioHistory))), v => v);
  if (_.keys(all).length < 1) {
    return null;
  }
  const merged = flattenStructure(all);

  const series = _.filter(_.map(reports, report => {
    if (!report.risk.riskRatios) {
      return null;
    }
    return {
      key: `${report.symbol}-${dataKey}`,
      connotation: _.get(value(report.risk), ['connotation'], 'NEUTRAL'),
      color: isSingular ? undefined : report.color,
      kicker: `${report.symbol}`
    };
  }), v => v);

  return (
    <div className='tc-risk-chart-card-wrap'>
      <Card>
        <CardContent>
          <Typography variant="h5" color="text.secondary">
            {title}
          </Typography>
          <div className='tc-risk-chart-wrap'>
            <ChartPreview
              data={merged}
              series={series as any}
              format='ratio'
            />  
          </div>
          {_.map(reports, report => {
            const actualValue = _.get(value(report.risk), ['value'], null);
            if (!actualValue) {
              return null;
            }
            return (
              <Typography variant="body2" color="text.secondary" gutterBottom key={report.symbol}>
                {description(report.risk.riskRatios.date, (
                  <>
                    <strong>${report.symbol}</strong>
                    {reports.length > 1 && (<> <Legend color={report.color} /></>)}
                  </>
                ), actualValue)}
              </Typography>
            );
          })}
          {helperText && (
            <Typography variant="body2" color="text.secondary">
              {helperText}
            </Typography>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
