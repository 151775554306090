import { CircularProgress } from "@mui/material";
import './loading.css';

export const FullPageLoading = () => {
  return (
    <div className="tc-loading-fullpage">
      <CircularProgress />
    </div>
  );
}
