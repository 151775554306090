import { Tab, Tabs } from "@mui/material";
import { Link, useMatch, useParams } from "react-router-dom";

export interface StockTabsParams {
  basePath: string;
}

export const StockTabs = ({ basePath }: StockTabsParams) => {
  const params = useParams();
  const currentTab = params.subpage ? `${basePath}/${params.subpage}` : basePath;
  const makePath = (path: string) => `${basePath}/${path}`;
  const risk = makePath('risk');
  // const dividends = makePath('dividends');
  // const fundamentals = makePath('business');

  return (
    <div className='tc-container'>
      <Tabs value={currentTab}>
        <Tab label="Summary" value={basePath} to={basePath} component={Link} />
        <Tab label="Risk" value={risk} to={risk} component={Link} />
        {/*
        <Tab label="Fundamentals" value={fundamentals} to={fundamentals} component={Link} />
        <Tab label="Dividends" value={dividends} to={dividends} component={Link} />
        <Tab label="Risk" value={risk} to={risk} component={Link} />
        */}
      </Tabs>
    </div>
  );
}
