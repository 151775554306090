import * as React from 'react';
import { Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import './mobile-menu.css';
import { Link } from 'react-router-dom';
import { logout } from '../../../utils/auth/login';
import { Login, Logout } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { useReactiveVar } from '@apollo/client';
import { authVar } from '../../../state/auth';

export const MobileMenu = () => {
  const auth = useReactiveVar(authVar);
  const [open, setOpen] = React.useState<boolean>(false);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const signOutAndClose = () => {
    logout();
    handleClose();
  }
  return (
    <div className='tc-header-mobile-menu'>
      <IconButton
        aria-label="more"
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor='left'
        open={open}
        onClose={handleClose}
      >
        <List>
          <ListItem style={{ width: '100%', display: 'block' }} component={Link} to='/' onClick={handleClose}>
            <div className='tc-header-mobile-menu-logo'>
              Trained Capital
            </div>
          </ListItem>
          <ListItem disablePadding component={Link} to='/' onClick={handleClose}>
            <ListItemButton>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary='Home' />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding component={Link} to='/browse' onClick={handleClose}>
            <ListItemButton>
              <ListItemIcon>
                <ShowChartIcon />
              </ListItemIcon>
              <ListItemText primary='Browse' />
            </ListItemButton>
          </ListItem>
          {auth.status === 'user' && (
            <ListItem disablePadding onClick={signOutAndClose}>
              <ListItemButton>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                <ListItemText primary='Sign out' />
              </ListItemButton>
            </ListItem>
          )}
          {auth.status === 'guest' && (
            <>
              <ListItem disablePadding component={Link} to='/signup' onClick={handleClose}>
                <ListItemButton>
                  <ListItemIcon>
                    <PersonAddAltIcon />
                  </ListItemIcon>
                  <ListItemText primary='Sign up' />
                </ListItemButton>
              </ListItem>
              <ListItem component={Link} to='/signin' onClick={handleClose}>
                <ListItemButton>
                  <ListItemIcon>
                    <Login />
                  </ListItemIcon>
                  <ListItemText primary='Log in' />
                </ListItemButton>
              </ListItem>
            </>
          )}
        </List>
      </Drawer>
    </div>
  );
}

