import * as _ from 'lodash';

export function flattenStructure(obj: any) {
  // Get the max length of inner arrays to determine the size of the result array
  const maxLength = _.max(_.map(obj, 'length'));
  
  const result: any = _.times(maxLength, () => ({}));

  _.forOwn(obj, (values, key) => {
    values.forEach((value: any, index: any) => {
      _.forOwn(value, (v: any, innerKey: any) => {
        const newKey = `${key}-${innerKey}`;
        result[index][newKey] = v;
      });
    });
  });

  return result;
}
