import React from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { BrowserRouter } from "react-router-dom";
import { Auth } from './auth/auth';

const client = new ApolloClient({
  uri: '/graphql',
  cache: new InMemoryCache(),
});

function App() {
  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <Auth />
        </ThemeProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
}

export default App;
