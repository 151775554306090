import './footer.css';

export const Footer = () => {
  return (
    <div className='tc-footer'>
      <div className='tc-footer-gradient'></div>
      <div className='tc-footer-copyright'>
        &copy; 2023 Eskridge Engineering Inc, a Texas company.
      </div>
    </div>
  );
}
