import * as React from 'react';
import { Card, CardContent, Typography } from "@mui/material";
import _ from "lodash";
import { ChartPreview } from "../../../../utils/chart-preview/chart-preview";
import { Connotation } from "../../../../utils/connote/connote";
import { toBigMoney, toMoney, toRatio } from "../../../../utils/format/format";
import { PriceDial } from '../../../../utils/price-dial/price-dial';
import { SummaryReports } from '../../../stock-types';
import { flattenStructure } from '../../../../utils/chart-preview/utils';
import { Legend } from '../../../../utils/color/legend';
import './price-card.css';

export interface GlanceProps {
  reports: SummaryReports;
}

export const PriceCard = ({ reports }: GlanceProps) => {
  const symbolRecord = reports[0].summary;
  const firstRecord: any = _.last(symbolRecord.priceSnapshotMonths);
  const lastRecord: any = _.first(symbolRecord.priceSnapshotMonths);
  const good = firstRecord.close < lastRecord.close;
  const isSingular = reports.length === 1;

  const keyedReports = _.keyBy(reports, report => report.summary.symbol);
  const allDividends = _.mapValues(keyedReports, report => _.reverse(_.cloneDeep(report.summary.priceSnapshotMonths)));
  const merged = flattenStructure(allDividends);

  const series = _.map(reports, report => ({
    key: `${report.symbol}-close`,
    connotation: good ? Connotation.GREAT : Connotation.TERRIBLE,
    color: isSingular ? undefined : report.color,
    kicker: `${report.symbol}`
  }));

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" color="text.secondary">
          Price
        </Typography>
        <div className='tc-summary-chart-wrap'>
          <ChartPreview
            data={merged}
            series={series}
            format='money'
          />  
        </div>
        {_.map(reports, report => {
          const start: any = _.last(report.summary.priceSnapshotMonths);
          const end: any = _.first(report.summary.priceSnapshotMonths);
          return (
            <div className='tc-summary-chart-price-item'>
              {!isSingular && (
                <Typography variant='body2' color="text.secondary" gutterBottom>
                  <strong>${report.symbol} <Legend color={report.color} /></strong>
                </Typography>
              )}
              <div className='tc-summary-chart-progression'>
                <div className='tc-summary-chart-progression-begin'>
                  <strong>{start.date}</strong>
                  <span>{toMoney(start.close)}</span>
                </div>
                <div className='tc-summary-chart-progression-arrow'>
                  &rarr;
                </div>
                <div className='tc-summary-chart-progression-end'>
                  <strong>{end.date}</strong>
                  <span>{toMoney(end.close)}</span>
                </div>
              </div>
              <PriceDial
                low={report.summary.price.y5.range.low}
                high={report.summary.price.y5.range.high}
                current={report.summary.price.y5.range.current}
              />
              <div className='tc-summary-chart-price-stats'>
                {_.get(report, ['summary', 'priceRatios', 'peRatio', 'value'], false) && (
                  <div className='tc-summary-chart-price-stat'>
                    <div className='tc-summary-chart-price-stat-name'>
                      Price-to-Earnings Ratio
                    </div>
                    <div className='tc-summary-chart-price-stat-value'>
                      {toRatio(report.summary.priceRatios.peRatio.value)}
                    </div>
                  </div>
                )}
                {_.get(report, ['summary', 'priceRatios', 'marketCap', 'value'], false) && (
                  <div className='tc-summary-chart-price-stat'>
                    <div className='tc-summary-chart-price-stat-name'>
                      Market Capitalization
                    </div>
                    <div className='tc-summary-chart-price-stat-value'>
                      {toBigMoney(report.summary.priceRatios.marketCap.value)}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </CardContent>
      {/*<CardActions>
        <Button size="small">More</Button>
      </CardActions>*/}
    </Card>
  );
}
