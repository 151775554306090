import * as React from 'react';
import classnames from 'classnames';
import { Typography } from '@mui/material';
import './explainer-note.css';

export interface ExplainerItemProps {
  note: string;
}

export const ExplainerNote = ({ note }: ExplainerItemProps) => {
  return (
    <div className='tc-explainer-note'>
      <Typography variant='body2'>
        {note}
      </Typography>
    </div>
  );
}
