import * as React from 'react';
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { CircularProgress, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import { authVar } from "../../../state/auth";
import { Link } from 'react-router-dom';
import BarChartIcon from '@mui/icons-material/BarChart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import * as _ from 'lodash';
import './watchlist.css';
import { RegisterPrompt } from '../../../utils/register-prompt/register-prompt';

const watchlistQuery = gql`
query watchlist($token: String!) {
  watchlist(token: $token) {
    symbol
    name
  }
}
`;

export const Watchlist = () => {
  const auth = useReactiveVar(authVar);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { data, loading, error } = useQuery(watchlistQuery, {
    variables: {
      token: _.get(auth, ['login', 'id'], null)
    },
    fetchPolicy: 'no-cache',
    skip: auth.status === 'guest' || !anchorEl
  });

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (auth.status === 'guest') {
    return (
      <div className='tc-header-watchlist'>
        <RegisterPrompt level='user'>
          <Tooltip placement='bottom' title='Watchlist'>
            <IconButton title='Watchlist' color='primary'>
              <ListAltIcon />
            </IconButton>
          </Tooltip>
        </RegisterPrompt>
      </div>
    );
  }

  if (auth.status === 'user' && auth.login) {
    return (
      <div className='tc-header-watchlist'>
        <Tooltip placement='bottom' title='Watchlist'>
          <IconButton title='Watchlist' onClick={handleClick} color='primary'>
            <ListAltIcon />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem disabled>
            Watchlist
          </MenuItem>
          {loading && <CircularProgress />}
          {(!loading && _.get(data, ['watchlist', 'length'], 0) === 0) && (
            <MenuItem disabled>
              Your watchlist is empty
            </MenuItem>
          )}
          {_.map(data?.watchlist, (item) => (
            <MenuItem component={Link} to={`/$${item.symbol}`}>
              <ListItemIcon>
                <BarChartIcon />
              </ListItemIcon>
              {item.name}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }

  return null;
};
