import { ChartPreviewBarSegment } from "../../../../utils/chart-preview-bar-segment/chart-preview-bar-segment";
import { ChartPreviewPie } from "../../../../utils/chart-preview-pie/chart-preview-pie";
import { SummaryReportRecord, SummaryReports } from "../../../stock-types";
import * as _ from 'lodash';

export interface DispersalChartProps {
  reports: SummaryReports;
}

export const DispersalChart = ({ reports }: DispersalChartProps) => {
  const isSingular = reports.length === 1;

  if (isSingular) {
    if (!_.get(reports, [0, 'summary', 'dispersal'], false)) {
      return null;
    }

    const series = [
      {
        key: 'shareholders',
        name: 'Shareholders',
        value: reports[0].summary.dispersal.totalToEquityHolders.value,
        color: '#ff9800'
      },
      {
        key: 'bondholders',
        name: 'Bondholders',
        value: reports[0].summary.dispersal.totalToDebtHolders.value,
        color: '#3f51b5'
      },
      {
        key: 'reinvestment',
        name: 'Reinvestment',
        value: reports[0].summary.dispersal.amountReinvested.value,
        color: '#00bcd4'
      },
    ];

    return (
      <ChartPreviewPie
        series={series}
      />
    );
  }

  const series = [
    {
      key: 'shareholders',
      name: 'Shareholders',
      value: reports[0].summary.dispersal.totalToEquityHolders.value,
      color: '#ff9800'
    },
    {
      key: 'bondholders',
      name: 'Bondholders',
      value: reports[0].summary.dispersal.totalToDebtHolders.value,
      color: '#3f51b5'
    },
    {
      key: 'reinvestment',
      name: 'Reinvestment',
      value: reports[0].summary.dispersal.amountReinvested.value,
      color: '#00bcd4'
    },
  ];

  const data = _.map(
    _.filter(reports, rep => _.get(rep, ['summary', 'dispersal', 'percentToEquityHolders'], false)),
    (report: SummaryReportRecord) => ({
      name: report.symbol,
      shareholders: report.summary.dispersal.percentToEquityHolders.value,
      bondholders: report.summary.dispersal.percentToDebtHolders.value,
      reinvestment: report.summary.dispersal.percentReinvested.value,
    })
  );

  return (
    <ChartPreviewBarSegment
      series={series}
      data={data}
    />
  );
}
