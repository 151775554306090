import { Checkbox, CircularProgress, FormControlLabel, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, TextField, Tooltip } from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox';
import './compare.css';
import { RiskReports, SummaryReports } from "../stock-types";
import React, { ChangeEvent, useState } from "react";
import * as _ from 'lodash';
import { gql, useQuery } from "@apollo/client";
import { useNavigate } from "react-router";
import BarChartIcon from '@mui/icons-material/BarChart';

const getSelected = (reports: SummaryReports | RiskReports | null): { symbol: string, name: string }[] => {
  if (!reports) {
    return [];
  }
  return _.filter(_.map(reports, report => {
    const item = _.find(report as any, prop => {
      return _.get(prop, ['name'], null);
    });
    return {
      name: item.name,
      symbol: item.symbol
    };
  }), v => v) as any;
}

const SearchQuery = gql`
query Search($query: String!) {
  search(query: $query) {
    items {
      symbol
      name
      path
    }
  }
}
`;

export interface CompareProps {
  reports: SummaryReports | RiskReports | null;
  symbols: string[];
}

export const Compare = ({ reports, symbols }: CompareProps) => {
  const symbolSet = new Set(symbols);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const [ inputValue, setInputValue ] = useState('');
  const { data, loading, error } = useQuery(SearchQuery, {
    variables: {
      query: inputValue
    },
    skip: !inputValue
  });

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.value);
  }

  const addToCompare = (symbol: string) => () => {
    const urlFragment = [ ...symbols, symbol ].map(s => `$${s}`).join(',');
    navigate(`/${urlFragment}`);
    setInputValue('');
  };

  const removeFromCompare = (symbol: string) => () => {
    const urlFragment = symbols.filter(s => s !== symbol).map(s => `$${s}`).join(',');
    navigate(`/${urlFragment}`);
  }

  const selectedStocks = getSelected(reports);
  const items = _.get(data, ['search', 'items'], []);

  return (
    <div className='tc-stock-compare'>
      <Tooltip title='Compare to ...' placement="bottom">
        <IconButton
          size="large"
          edge="start"
          color="primary"
          aria-label="menu"
          onClick={handleClick}
        >
          <AddBoxIcon />
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className='tc-stock-compare-select-wrap'>
          <div className='tc-stock-compare-select'>
            {selectedStocks.length === 0 && <CircularProgress />}
            {selectedStocks.map((item) => (
              <div className='tc-stock-compare-select-current-item' key={item.symbol}>
                <FormControlLabel key={item.symbol} control={(
                  <Checkbox
                    onClick={removeFromCompare(item.symbol)}
                    defaultChecked
                    disabled={selectedStocks.length === 1}
                  />
                )} label={item.name} />
              </div>
            ))}
          </div>
          <TextField value={inputValue} fullWidth onChange={onSearch} placeholder="Search by symbol or name ..." />
          {(items.length > 0) && (
            <List>
              {_.map(items, (item: any) => !symbolSet.has(item.symbol) && (
                <ListItemButton
                  key={item.symbol}
                  onClick={addToCompare(item.symbol)}
                >
                  <ListItemIcon>
                    <BarChartIcon />
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              ))}
            </List>
          )}
        </div>
      </Popover>
    </div>
  );
};
