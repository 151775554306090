
export const toPercent = (num: number) => {
  if (num > 1) {
    return `${Math.round(num * 100)}%`
  }
  if (num < 0) {
    return `${Math.round(num * 1000) / 10}%`
  }
  if (num >= .10) {
    return `${Math.round(num * 1000) / 10}%`
  }
  return `${Math.round(num * 10000) / 100}%`
}

export const formatDollars = (num: number, decimals = 2) => {
  const formatted = num.toFixed(decimals);
  return `$${formatted.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

export const toMoney = (num: number) => {
  const rounded = Math.round(num * 100) / 100;
  return formatDollars(rounded);
}

export const toBigMoney = (number: number, decimals: number = 0) => {
  const scaleLabels = ['T', 'B', 'M', 'k', ''];
  const scales = [1e12, 1e9, 1e6, 1e3, 1];

  for (let i = 0; i < scales.length; i++) {
    if (number >= scales[i]) {
      const scaledValue = number / scales[i];
      return formatDollars(Number(scaledValue.toFixed(decimals)), 0) + scaleLabels[i];
    }
  }

  return '0';
}

export const toMoneyLong = (number: number, decimals: number = 2) => {
  const scaleLabels = ['trillion', 'billion', 'million', 'thousand', ''];
  const scales = [1e12, 1e9, 1e6, 1e3, 1];

  for (let i = 0; i < scales.length; i++) {
    if (number >= scales[i]) {
      const scaledValue = number / scales[i];
      return formatDollars(Number(scaledValue.toFixed(decimals)), 2) + ' ' + scaleLabels[i];
    }
  }

  return '0';
}

export const toRatio = (number: number) => {
  return number.toFixed(2);
}
